.InputDialog {
  display: block;
  position: relative;
  width: 100%;
  max-width: 600px;
  padding: 10px;
}

.InputDialog h1 {
  margin: 0 20px 20px 0px !important;
  color: #505458;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
}
