.ProductList .toolbar-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.87);
  padding: 20px 20px 0 20px !important;
  margin-bottom: 0;
}

.ProductList .wrapper {
  border-top: 4px solid rgb(0, 125, 198);
  border-radius: 10px;
}

.ProductList .toolbar-button {
  padding: 10px 20px 0px 0px;
}
