.MuiInputBase-root {
  border-radius: 7px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 2px solid #008fe3;
}

.MuiPickersDay-daySelected, .MuiPickersDay-daySelected:hover {
  background-color: #008fe3;
}

.MuiFormLabel-root.Mui-focused {
  color: #007DC6;
}

.MuiFormControl-root {
  width: 100% !important;
}

.input-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 20px 0 0 0;
}

.input-content {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}

.input-content-38 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
  line-height: 38px;
}

.input-label-old {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
  font-size: 14px;
  height: 38px;
  line-height: 38px;
  color: #353C4E;
}

.input-label-high {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
  font-size: 14px;
  height: 54px;
  line-height: 54px;
  color: #353c4e;
}

.input-label {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30%;
  flex: 0 0 30%;
  max-width: 30%;
  font-size: 14px;
  height: 38px;
  line-height: 38px;
  color: #353c4e;
}

.input-width-45 {
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
}

.input-width-33 {
  -ms-flex: 0 0 45%;
  flex: 0 0 45%;
  max-width: 45%;
}

.input-width-60 {
  -ms-flex: 0 0 60%;
  flex: 0 0 60%;
  max-width: 60%;
}

.input-checkbox {
  margin: 8px 15px;
}

/* BUTTONS */

.modal-buttons {
	display: flex;
	justify-content: space-between;
	align-self: flex-end;
	width: 100%;
	margin-top: auto;
  padding: 40px 0 15px 0;
}

.button-icon {
	margin-top: 2px !important;
}

.btn-25 {
	display: flex;
	justify-content: center;
	padding: 3px 0px;
	height: 32px;
	width: 25%;
	color: white;
	line-height: 30px;
	overflow: visible;
	border-radius: 5px;
}

.btn-33 {
	display: flex;
	justify-content: center;
	padding: 3px 0px;
	height: 32px;
	width: 30%;
	color: white;
	line-height: 30px;
	overflow: visible;
	border-radius: 5px;
}

.btn-50 {
	display: flex;
	justify-content: center;
	padding: 3px 0px;
	height: 32px;
	width: 47%;
	color: white;
	line-height: 30px;
	overflow: visible;
	border-radius: 5px;
}

.btn-85 {
	display: flex;
	justify-content: center;
	padding: 3px 0px;
	height: 32px;
	width: 85%;
	color: white;
	line-height: 30px;
	overflow: visible;
	border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .modal-buttons {
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 0;
  }

  .btn-33, .btn-25, .btn-50 {
    width: 100% !important;
    margin-bottom: 10px;
  }

  .input-label {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .input-label-high {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
  }

  .input-width-33 {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }

  .input-width-45 {
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }

  .input-width-60 {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
  }
}

.button-text {
	font-size: 12px;
	font-weight: 500;
	padding: 1px 8px 0 5px;
	height: 20px;
	line-height: 26px;
	text-transform: uppercase;
}

.edit-button {
	background: #008fe3;
}

.edit-button:hover {
	cursor: pointer;
	background: #007DC6;
}

.remove-button {
	background: #e15f71;
}

.remove-button:hover {
	cursor: pointer;
	background: #e03d53;
}

.goback-button {
	background: #999;
}

.goback-button:hover {
	cursor: pointer;
	background: #777;
}

.preview-button {
	background: #999;
}

.preview-button:hover {
	cursor: pointer;
	background: #777;
}

.expand-button {
	border-radius: 50%;
	padding: 10px;
  width: 43px;
  height: 43px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Tanja's addition */

input.input-checkbox {
  transform: scale(2);
  margin-right: 10px;
}

.input-with-checkbox {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 38px;
}

.price-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 6px;
}

.price-label {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  font-size: 14px;
  color: #353c4e;
}

.margin-bottom-30 {
	margin-bottom: 30px;
}

.padding-left-10 {
  padding-left: 10px
}
