/* BUTTONS */

.modal-buttons {
	display: flex;
	justify-content: space-between;
	align-self: flex-end;
	width: 100%;
	margin-top: auto;
}

.button-icon {
	margin-top: 3px;
}

.btn-33 {
	display: flex;
	justify-content: center;
	padding: 3px 0px;
	height: 32px;
	width: 30%;
	color: white;
	line-height: 30px;
	overflow: visible;
	border-radius: 5px;
}

.btn-50 {
	display: flex;
	justify-content: center;
	padding: 3px 0px;
	height: 32px;
	width: 47%;
	color: white;
	line-height: 30px;
	overflow: visible;
	border-radius: 5px;
}

.button-text {
	font-size: 12px;
	font-weight: 500;
	padding: 1px 8px 0 5px;
	height: 20px;
	line-height: 26px;
	text-transform: uppercase;
}

.edit-button {
	background: #008fe3;
}

.edit-button:hover {
	cursor: pointer;
	background: #007DC6;
}

.remove-button {
	background: #e15f71;
}

.remove-button:hover {
	cursor: pointer;
	background: #e03d53;
}

.goback-button {
	background: #999;
}

.goback-button:hover {
	cursor: pointer;
	background: #777;
}
