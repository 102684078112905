.MainStorage {
  position: relative;
  min-width: 360px;
}

.MainStorage .title-row {
  position: relative;
  width: 100%;
  background-color: rgb(0, 125, 198);
  height: 50px;
  line-height: 50px;
  margin: 0;
  text-align: center;
  color: white;
  font-family: "Roboto", "Tahoma", "Geneva", sans-serif;
  font-weight: 500;
  letter-spacing: 0.02857em;
  font-size: 2em;
}

.MainStorage .content-wrapper {
  padding: 0 30px;
  margin: 0 auto;
  overflow: auto;
}

.MainStorage .col-left {
  position: relative;
  width: 275px;
  float: left;
  margin: 30px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.27);
  background-color: white;
  box-sizing: border-box;
}

.MainStorage .col-right {
  position: relative;
  width: calc(100% - 300px);
  float: left;
  margin: 30px 0 30px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.27);
  background-color: white;
  box-sizing: border-box;
}

@media only screen and (max-width: 1220px) {
  .MainStorage .col-left {
    position: relative;
    width: 100%;
    float: none;
    min-width: 250px;
    margin-bottom: 0px;
  }

  .MainStorage .col-right {
    position: relative;
    width: 100%;
    float: none;
    margin-top: 30px;
    margin-left: 0px;
  }
}
