footer {
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin: 0 auto;
	background-color: #3d3d3d;
	background-repeat: repeat-x;
	color: white;
	position: relative;
	bottom: 0;
	z-index: 1000;
}

footer div {
	display: flex;
	justify-content: space-between;
	width: 80%;
	padding: 10px;
}

footer a:link {
	text-decoration: underline;
	color: white;
}

footer a:visited {
	text-decoration: underline;
	color: white;
}

footer a:hover {
	color: #22adff;
	text-decoration: underline;
}

footer a:active {
	text-decoration: underline;
	color: #22adff;
}

a:link {
	text-decoration: none;
	color: #0000ee;
}

a:visited {
	text-decoration: none;
	color: #0000ee;
}

a:hover {
	color: #0070b2;
	text-decoration: none;
}

a:active {
	text-decoration: none;
	color: #22adff;
}

@media print {
	.hide-print {
		display: none;
	}
}
