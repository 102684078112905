.ProductGroupList {
  min-height: 200px;
  height: 50vh;
  overflow: auto;
}

@media only screen and (max-width: 1220px) {
  .ProductGroupList {
    height: 200px;
    min-height: 200px;
    overflow: auto;
  }
}
