.ShowProduct h1 {
  font-size: 30px;
  margin-bottom: 16px !important;
}

.ShowProduct .category {
  font-size: 16px;
}

@media only screen and (max-width: 960px) {
  .MuiGrid-direction-xs-column {
    border-right: none !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 0 !important;
  }
}
