.HeaderBar {
	display: flex;
	flex: 1 0 auto;
	min-height: 100%;
}

.HeaderBar .navbar {
	top: 0;
  left: auto;
  right: 0;
  position: fixed;
	width: 100%;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
	color: #fff;
	box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-bottom: 3px solid #007DC6;
  background-color: #333 !important;
	flex: 0 0 auto;
}

.HeaderBar .navbar-wrapper {
	display: flex;
	position: relative;
	align-items: center;
	height: 64px;
	padding-left: 24px;
  padding-right: 24px;
}

.HeaderBar img {
	margin-top: 3px;
	margin-left: 10px;
}

@media only screen and (max-width: 600px) {
	.HeaderBar .navbar-wrapper {
		height: 56px;
  }
}

.HeaderBar .logout-button {
	color: #fff !important;
	border-color: #333 !important;
	background-color: #333 !important;
}

.HeaderBar .exit-icon {
	padding-right: 5px;
}

.HeaderBar .person-wrapper {
	margin-top: 30px;
	padding-left: 16px !important;
	color: #333 !important;
	display: flex;
	flex-direction: column;
}

.HeaderBar .person-icon {
	font-size: 100px !important;
	border-radius: 100px;
	border: 1px solid rgba(0,0,0,0.50);
	padding: 5px;
	margin-bottom: 5px;
	color: #333;
}

.HeaderBar .menu-wide {
	background-color: white;
	height: 100%;
	max-height: 100%;
	width: 240px;
	transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	padding-top: 64px;
}

.HeaderBar .menu-narrow {
	background-color: white;
	height: 100%;
	max-height: 100%;
	width: 73px;
	transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
	overflow: hidden;
	border-right: 1px solid rgba(0, 0, 0, 0.12);
	padding-top: 64px;
}

.HeaderBar .MuiListItemText-primary {
	font-size: 16px !important;
}

.HeaderBar .menu-item {
	padding-left: 24px;
}

.HeaderBar .menu-sub-item {
	padding-left: 32px !important;
}

.HeaderBar .active {
	color: #FFF;
	background-color: #333;
}

.HeaderBar .inactive {
	color: #333;
}

.HeaderBar .active:hover {
	color: #FFF;
	background-color: #333;
}

.HeaderBar .MuiListItemIcon-root .MuiSvgIcon-root {
	font-size: 22px !important;
}

.MuiAppBar-colorPrimary {
	border-bottom: 3px solid #007DC6;
  background-color: #333 !important;
}

main {
	margin-top: 64px;
	width: 100%;
	overflow: auto;
}

main .MuiAppBar-colorPrimary {
	background-color: white !important;
	border-bottom: none;
}

@media only screen and (max-width: 719px) {
	.HeaderBar .menu-wide {
		position: fixed;
		top: 67px;
		left: 0;
		width: 100%;
		z-index: 1110;
		padding-top: 0;
  }

  .HeaderBar .menu-narrow {
		position: fixed;
		width: 0;
		top: 59px;
		left: 0;
    visibility: hidden;
		transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

	main {
		margin-top: 59px;
	}
}

@media only screen and (max-width: 600px) {
	.HeaderBar .menu-wide {
		top: 59px;
  }
}

@media print {
	.HeaderBar .hide-print {
		display: none;
	}
}
