.ModalButtons .modal-button-row {
  display: flex;
  justify-content: flex-end;
}

.ModalButtons .button {
	display: flex;
	justify-content: center;
	padding: 3px 0px;
	height: 32px;
	width: 120px;
	color: white;
	line-height: 30px;
	overflow: visible;
	border-radius: 5px;
}

.ModalButtons .button-text {
	font-size: 12px;
	font-weight: 500;
	padding: 1px 8px 0 5px;
	height: 20px;
	line-height: 26px;
	text-transform: uppercase;
}

.ModalButtons .button-icon {
	margin-top: 3px;
}

.ModalButtons .close-button {
  width: 40px;
  height: 40px;
  color: #999;
  margin: 0px;
}

.ModalButtons .close-button:hover {
	cursor: pointer;
	color: #777;
}

.ModalButtons .close-button .MuiSvgIcon-root {
  font-size: 30px;
  line-height: 40px;
}
