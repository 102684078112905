* {
	margin: 0px;
	padding: 0px;
	font-family: Roboto, Tahoma, Geneva, sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  background-color: #f4f6f8 !important;
  overflow-x: hidden;
  overflow-y: auto;
  margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

header {
  flex: 0 0 auto;
}

footer {
  flex: 0 0 auto;
  height: 65px;
}

input {
    box-sizing: content-box;
}

.title-row {
	width: 100%;
  background-color: #007DC6;
  height: 50px;
  color: #FFF;
  text-align: center;
  line-height: 50px;
  font-weight: 500;
  letter-spacing: 0.02857em;
  font-size: 2em;
}

.makeStyles-root-1 {
  flex: 1 0 auto;
}

.MuiDivider-root {
    width: auto;
}

.d-flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.text-right {
    text-align: right;
}

.link {
    cursor: pointer;
    text-decoration: underline;
}

.nowrap-text {
    white-space: nowrap;
}

.loader {
  display: block;
  position: relative;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.mini-loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.button-loader {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 22px;
  height: 22px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.medium-loader {
  border: 9px solid #ddd; /* Light grey */
  border-top: 9px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
	margin: 60px auto;
}

.large-loader {
  border: 12px solid #ddd; /* Light grey */
  border-top: 12px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
	margin: 60px auto;
}

.modal-loader-wrapper {
  display: block;
  position: relative;
  width: 100%;
  max-width: 600px;
  min-width: 320px;
}

.modal-loader {
  display: block;
  position: relative;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

.modal-h1 {
  margin: 0 20px 40px 0 !important;
  color: #505458;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
}

.padding-top-0 {
  padding-top: 0px !important;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-60 {
  margin-top: 60px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-bottom-15 {
	margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-30 {
	margin-bottom: 30px !important;
}

@-webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.link-color {
  font-size: 13px;
  color: #4395c4 !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.MuiButton-label {
  font-size: 12px !important;
}
