.EditCategory {
  display: block;
  position: relative;
  max-width: 1160px;
  width: 100%;
  padding: 10px 10px 0 10px;
}

.EditCategory .wrapper {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin: 30px 0;
  padding: 5px 10px;
  overflow: auto;
  box-sizing: border-box;
}

.EditCategory .title {
  display: block;
  position: relative;
  margin-bottom: 10px;
}
