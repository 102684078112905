.MaterialComTable {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
}

.MaterialComTable tr {
  border: none !important;
}

.MaterialComTable tr:nth-child(even) {
  background-color: #EEE !important;
}

.MaterialComTable tr:hover {
  background-color: #bfe8ff !important;
}

.MuiTableCell-body {
  border-bottom: none !important;
}

.MuiTableFooter-root tr:hover {
  background-color: white !important;
}
