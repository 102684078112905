.AddProduct {
  display: block;
  position: relative;
  max-width: 1160px;
  width: 100%;
  padding: 10px 10px 0 10px;
}

@media only screen and (max-width: 960px) {
  .MuiGrid-direction-xs-column {
    border-right: none !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 0 !important;
  }
}
